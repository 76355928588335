@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "MonumentExtended";
  src: local("MonumentExtended"),
    url("/public/font/MonumentExtended-Regular.otf") format("opentype");
  /* font-display: swap; */
}
@font-face {
  font-family: "MonumentExtendedUltrabold";
  src: local("MonumentExtendedUltrabold"),
    url("/public/font/MonumentExtended-Ultrabold.otf") format("opentype");
  /* font-display: swap; */
}

.herobg {
  /* background-image: url(../public//assets/images/andytate.png); */
  background-repeat: no-repeat;
  /* background-color: black; */
}

.aboutdiv {
  background: linear-gradient(
    to left,
    rgb(44, 45, 41, 90%),
    rgb(255, 255, 255, 10%)
  );
}

.nest {
  background: url(../public//assets//images/coinnext2.png) #9c6bd0;
  z-index: 99;
  position: relative;
  background-position: center;
  background-size: cover;
  /* background-color: black; */
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}

.bounce-animation {
  animation: bounce 3s ease-in-out infinite;
}
